<template>
    <div id="leftSide" class="leftBar" v-click-outside="onClickOutside">
        <nav id="navBar" class="navBar1" 
        :style="{'height': `calc(100% - ${filterSectionHeight}px)`}">
            <ul>
               <li @click="opennoegg(0)" :class="index == 0 ? 'active': ''" >
                    <router-link to="/swap">
                        <span class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <defs>
                                </defs>
                                <title>swap</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="swap">
                                        <g id="swap-2" data-name="swap">
                                            <rect class="cls_swap" width="24" height="24" transform="translate(0 24) rotate(-90)"/>
                                            <path class="cls_swap2" d="M4,9H17l-1.6,1.2a1,1,0,0,0-.2,1.4,1,1,0,0,0,.8.4,1,1,0,0,0,.6-.2l4-3a1,1,0,0,0,0-1.59l-3.86-3a1,1,0,0,0-1.23,1.58L17.08,7H4A1,1,0,0,0,4,9Z"/>
                                            <path class="cls_swap2" d="M20,16H7l1.6-1.2a1,1,0,0,0-1.2-1.6l-4,3a1,1,0,0,0,0,1.59l3.86,3a1,1,0,0,0,.61.21,1,1,0,0,0,.79-.39,1,1,0,0,0-.17-1.4L6.92,18H20a1,1,0,0,0,0-2Z"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Swap
                    </router-link>
                </li>
                <li @click="opennoegg(1)" :class="index == 1 ? 'active': ''">
                    <router-link to="/liquidity">
                        <span class="icon">
                            <svg version="1.1" id="Layer_1_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                            <g id="Guide">
                                <g>
                                    <path d="M24,32c0,4.411,3.589,8,8,8s8-3.589,8-8s-3.589-8-8-8S24,27.589,24,32z M38,32c0,3.309-2.691,6-6,6s-6-2.691-6-6
    s2.691-6,6-6S38,28.691,38,32z"/>
                            <path d="M57,27c-2.414,0-4.434,1.721-4.899,4H47v-6c0-0.358-0.192-0.69-0.504-0.868l-3.318-1.896l7.069-7.069
                            C51.038,15.691,51.983,16,53,16c2.757,0,5-2.243,5-5s-2.243-5-5-5s-5,2.243-5,5c0,1.017,0.309,1.962,0.833,2.753l-7.455,7.455
                            L33,16.42v-4.521c2.279-0.465,4-2.484,4-4.899c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,2.414,1.721,4.434,4,4.899v4.521l-8.378,4.788
                            l-7.455-7.455C15.691,12.962,16,12.017,16,11c0-2.757-2.243-5-5-5s-5,2.243-5,5s2.243,5,5,5c1.017,0,1.962-0.309,2.753-0.833
                            l7.069,7.069l-3.318,1.896C17.192,24.31,17,24.642,17,25v6h-5.101C11.434,28.721,9.414,27,7,27c-2.757,0-5,2.243-5,5s2.243,5,5,5
                            c2.414,0,4.434-1.721,4.899-4H17v6c0,0.358,0.192,0.69,0.504,0.868l3.318,1.896l-7.069,7.069C12.962,48.309,12.017,48,11,48
                            c-2.757,0-5,2.243-5,5s2.243,5,5,5s5-2.243,5-5c0-1.017-0.309-1.962-0.833-2.753l7.455-7.455L31,47.58v4.521
                            c-2.279,0.465-4,2.484-4,4.899c0,2.757,2.243,5,5,5s5-2.243,5-5c0-2.414-1.721-4.434-4-4.899V47.58l8.378-4.788l7.455,7.455
                            C48.309,51.038,48,51.983,48,53c0,2.757,2.243,5,5,5s5-2.243,5-5s-2.243-5-5-5c-1.017,0-1.962,0.309-2.753,0.833l-7.069-7.069
                            l3.318-1.896C46.808,39.69,47,39.358,47,39v-6h5.101c0.465,2.279,2.484,4,4.899,4c2.757,0,5-2.243,5-5S59.757,27,57,27z M53,8
                            c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S51.346,8,53,8z M11,14c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3
                            S12.654,14,11,14z M7,35c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S8.654,35,7,35z M11,56c-1.654,0-3-1.346-3-3s1.346-3,3-3
                            s3,1.346,3,3S12.654,56,11,56z M53,50c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S51.346,50,53,50z M29,7c0-1.654,1.346-3,3-3
                            s3,1.346,3,3s-1.346,3-3,3S29,8.654,29,7z M35,57c0,1.654-1.346,3-3,3s-3-1.346-3-3s1.346-3,3-3S35,55.346,35,57z M45,38.42
                            l-13,7.429L19,38.42V25.58l13-7.429l13,7.429V38.42z M57,35c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S58.654,35,57,35z"/>
                            </g></g>
                            </svg>
                        </span>
                        Liquidity
                    </router-link>
                </li>

                <li @click="opennoegg(3)" :class="index == 3 ? 'active': ''">
                    <router-link to="/farms">
                        <span class="icon">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.56 10.56" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 10.56 10.56">
                                <g>
                                    <g>
                                    <path fill="#020400" d="m7.44,0c-0.133,0-0.24,0.107-0.24,0.24v2.665c-0.613,0.202-1.185,0.533-1.68,0.986v-2.931c0-0.133-0.107-0.24-0.24-0.24s-0.24,0.107-0.24,0.24v2.931c-0.496-0.453-1.069-0.784-1.68-0.986v-2.665c0-0.133-0.108-0.24-0.24-0.24-0.133,0-0.24,0.107-0.24,0.24v6.239h0.028c0.155,1.14 1.033,2.033 2.132,2.147v1.692c0,0.133 0.107,0.24 0.24,0.24s0.24-0.107 0.24-0.24v-1.691c1.099-0.114 1.977-1.008 2.132-2.147h0.028v-6.24c0-0.133-0.107-0.24-0.24-0.24zm-2.401,8.142c-0.91-0.12-1.626-0.917-1.68-1.901v-0.679c0.547,0.203 1.056,0.517 1.489,0.951 0.066,0.067 0.13,0.137 0.19,0.207v1.422zm.001-2.092c-0.495-0.452-1.068-0.782-1.679-0.985v-1.661c0.547,0.203 1.058,0.517 1.489,0.949 0.066,0.067 0.13,0.137 0.19,0.207v1.49zm2.161,.19c-0.054,0.985-0.77,1.781-1.68,1.902v-1.421c0.061-0.07 0.124-0.14 0.19-0.207 0.436-0.437 0.942-0.751 1.49-0.953v0.679zm0-1.175c-0.613,0.203-1.185,0.533-1.68,0.985v-1.489c0.061-0.07 0.124-0.14 0.19-0.207 0.432-0.433 0.943-0.747 1.49-0.95v1.661z"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Farms
                    </router-link>
                </li>
                <li @click="opennoegg(4)" :class="index == 4 ? 'active': ''">
                    <router-link to="/staking">
                        <span class="icon staking">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                <g fill="none" stroke="currentColor">
                                    <path d="M3.5 5.5V3a2.5 2.5 0 015 0v2.5"/>
                                    <rect width="9" height="6" x="1.5" y="5.5" rx=".5" ry=".5"/>
                                </g>
                            </svg>
                        </span>
                        Staking
                    </router-link>
                </li><!--
                 <li @click="opennoegg(2)" :class="index == 2 ? 'active': ''">
                    <router-link to="/pools">
                        <span class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-view-dashboard-outline" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19,5V7H15V5H19M9,5V11H5V5H9M19,13V19H15V13H19M9,17V19H5V17H9M21,3H13V9H21V3M11,3H3V13H11V3M21,11H13V21H21V11M11,15H3V21H11V15Z" />
                            </svg>
                        </span>
                        Pools
                    </router-link>
                </li>-->
                <li @click="opennoegg(6)" :class="index == 6 ? 'active': ''">
                    <router-link to="/holders">
                        <span class="icon" >
                            <img src="@/assets/images/card.png" style="width:20px">
                        </span>
                        OG Stars
                    </router-link>
                </li>
                <!-- <li @click="opennoegg(8)" :class="index == 8 ? 'active': ''">
                    <router-link to="/private">
                        <span class="icon" >
                            <img src="@/assets/images/exchange.png" style="width:20px">
                        </span>
                        Star Exchanger
                    </router-link>
                </li>
                <li @click="opennoegg(14)" :class="index == 14 ? 'active': ''">
                    <router-link to="/privateEx">
                        <span class="icon" >
                            <img src="@/assets/images/exchange.png" style="width:20px">
                        </span>
                        Star ExchangerEx
                    </router-link>
                </li>-->
                <!-- <li @click="opennoegg(5)" :class="index == 5 ? 'active': ''">
                    <router-link to="/nftStaking">
                        <span class="icon">
                            <img src="@/assets/images/nftStaking1.png" style="width:20px">
                        </span>
                        NFT Staking
                    </router-link>
                </li> -->
                <!--<li @click="opennoegg(7)" :class="index == 7 ? 'active': ''">
                    <router-link to="/skywalkers">
                        <span class="icon" >
                            <img src="@/assets/images/skyw.png" style="width:20px">
                        </span>
                        Skywalkers
                    </router-link>
                </li>
                <li @click="opennoegg(9)" :class="index == 9 ? 'active': ''">
                    <router-link to="/launch">
                        <span class="icon" >
                            <img src="@/assets/images/launch.png" style="width:20px">
                        </span>
                        Launch
                    </router-link>
                </li>
                <li @click="opennoegg(10)" :class="index == 10 ? 'active': ''">
                    <router-link to="/treasury">
                        <span class="icon" >
                            <img src="@/assets/images/treasury.png" style="width:20px">
                        </span>
                        Treasury
                    </router-link>
                </li>
                <li @click="opennoegg(11)" :class="index == 11 ? 'active': ''">
                    <router-link to="/StarBall">
                        <span class="icon" >
                            <img src="@/assets/images/lotto.png" style="width:20px">
                        </span>
                        StarBall
                    </router-link>
                </li> 
                <li @click="opennoegg(12)" :class="index == 12 ? 'active': ''">
                    <router-link to="/info">
                        <span class="icon">
                            <img src="@/assets/images/info.png" style="width:20px">
                        </span>
                        Info
                    </router-link>
                </li>
                <li @click="opennoegg(10)" :class="index == 10 ? 'active': ''">
                    <router-link to="/treasury">
                        <span class="icon" >
                            <img src="@/assets/images/treasury.png" style="width:20px">
                        </span>
                        Treasury
                    </router-link>
                </li>-->
                <li @click="opennoegg(13)" :class="index == 13 ? 'active': ''">
                    <router-link to="/governance">
                        <span class="icon">
                            <img src="@/assets/images/vote.png" style="width:20px">
                        </span>
                        Star DAO
                    </router-link>
                </li> 
            </ul>
        </nav>
        <nav id="navBar" class="navBar2">
            <ul>
                <hr style="border-top:1px solid #1d485f;">
                <li @click="opennoegg(100)" :class="index == 100 ? 'active': ''">
                <a href="https://discord.gg/dqyJQ7gUkb" target="_blank">
                        <span class="icon">
                        
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-discord" width="20" height="20" viewBox="2 2 18 18"><path d="M22,24L16.75,19L17.38,21H4.5A2.5,2.5 0 0,1 2,18.5V3.5A2.5,2.5 0 0,1 4.5,1H19.5A2.5,2.5 0 0,1 22,3.5V24M12,6.8C9.32,6.8 7.44,7.95 7.44,7.95C8.47,7.03 10.27,6.5 10.27,6.5L10.1,6.33C8.41,6.36 6.88,7.53 6.88,7.53C5.16,11.12 5.27,14.22 5.27,14.22C6.67,16.03 8.75,15.9 8.75,15.9L9.46,15C8.21,14.73 7.42,13.62 7.42,13.62C7.42,13.62 9.3,14.9 12,14.9C14.7,14.9 16.58,13.62 16.58,13.62C16.58,13.62 15.79,14.73 14.54,15L15.25,15.9C15.25,15.9 17.33,16.03 18.73,14.22C18.73,14.22 18.84,11.12 17.12,7.53C17.12,7.53 15.59,6.36 13.9,6.33L13.73,6.5C13.73,6.5 15.53,7.03 16.56,7.95C16.56,7.95 14.68,6.8 12,6.8M9.93,10.59C10.58,10.59 11.11,11.16 11.1,11.86C11.1,12.55 10.58,13.13 9.93,13.13C9.29,13.13 8.77,12.55 8.77,11.86C8.77,11.16 9.28,10.59 9.93,10.59M14.1,10.59C14.75,10.59 15.27,11.16 15.27,11.86C15.27,12.55 14.75,13.13 14.1,13.13C13.46,13.13 12.94,12.55 12.94,11.86C12.94,11.16 13.45,10.59 14.1,10.59Z"/></svg>
                                    
                        </span>
                        Discord
                    </a>
                </li>
                <li @click="opennoegg(101)" :class="index == 101 ? 'active': ''">
                    <a href="https://twitter.com/StarSwap" target="_blank"> 
                        <span class="icon">
                        <svg enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M4,440.5c55.6,5,105.7-9,151.3-43.2c-47.7-4.2-79.6-28-97.4-72.6c15.6,1.9,30.2,2.4,45.7-1.8   c-51.4-16-78.8-49.6-82.5-103.8c15.4,7.2,29.9,12.4,47,12.6c-30.5-22.9-46.1-52.6-45.5-90c0.3-17.2,4.9-33.4,14-48.7   C93.1,159.1,164,195.7,251.3,201.8c-0.5-3.8-0.8-6.8-1.2-9.9c-7.2-55.4,28.8-105.8,83.8-116.3c34.5-6.6,65,2.5,90.8,26.3   c4,3.6,7.4,4.4,12.4,3.1c20.1-5.1,39.2-12.5,57.7-22.5c-7.1,23.4-21.7,41-41.5,55.8c4.5-0.8,9.1-1.4,13.6-2.3   c4.7-1,9.4-2.1,14.1-3.4c4.5-1.2,8.9-2.6,13.3-4.1c4.5-1.5,9-3.2,14.3-4.1c-2.6,3.6-5.1,7.4-7.9,10.9c-11.6,14.7-25,27.6-39.7,39.1   c-1.5,1.2-2.8,3.8-2.7,5.6c0.8,35.5-4.2,70.1-15.7,103.7c-22.6,66.2-62,119.8-121.1,158.1c-29.2,18.9-61.1,31.3-95.2,38.5   c-33.8,7.1-67.8,8.4-101.9,4.4c-34.2-4-66.7-14.1-97.3-29.9c-8.1-4.1-15.9-8.7-23.8-13.1C3.6,441.3,3.8,440.9,4,440.5z"/></g></svg>
                        </span>
                        Twitter
                    </a>
                </li><!--
                <li @click="opennoegg(102)" :class="index == 102 ? 'active': ''">
                    <a href="https://docs.starswap.net/ama-faq/ama-with-gaiastarter" target="_blank">
                        <span class="icon">
                            <img src="@/assets/images/feedback.svg" style="width:20px">
                        </span>
                        FAQ
                    </a>
                </li>-->
                <li @click="opennoegg(103)" :class="index == 103 ? 'active': ''">
                    <a href="https://docs.starswap.net" target="_blank">
                        <span class="icon">
                            <img src="@/assets/images/docs.svg" style="width:20px">
                        </span>
                        Docs
                    </a>
                </li>
            </ul>
        </nav>
        <!-- <nav id="navBar" class="navBar3" style="text-align:center; color:white; font-weight: 400; font-size: .8rem;">
            <div class="btn mt-2 walletBtn">
                <span class="mr-2 avatar">
                    <img :src="require('@/assets/images/tokens/astr.png')" style="width:20px; height:20px">
                </span>
                ASTR
            </div>
        </nav> -->
        <nav id="navBar" class="navBar3" 
            style="text-align:center; color:white; font-weight: 400; font-size: .6rem;">
            <div class="row" style="margin:2px; padding:4px 4px;">
                <div class="col-6 " style="padding:0; ">
                    <div class="btn" title="ASTR Price" style="padding: 4px 4px 4px 4px;">
                        <span class="mr-1">
                            <img :src="require('@/assets/images/tokens/astr.png')" 
                            style="width:15px; height:15px;margin-bottom:3px">
                        </span>
                            ${{getASTRPrice}}
                    </div>
                </div>
                <div class="col-6" style="padding:0; ">
                    <div class="btn btnBlue" title="Add $STAR to Metamask" @click="addSTARToken" style="padding: 4px 4px 4px 4px;">
                        <span class="mr-1">
                            <img :src="require('@/assets/images/tokens/star.png')" 
                            style="width:15px; height:15px; margin-bottom:3px">
                        </span>
                            ${{getSTARPrice}}
                    </div>
                </div>
            </div>
            <div style="font-size:13px; font-weight:400;">
                Current Version: 1.5
            </div>
        </nav>
    </div>
</template>
<script>
    import vClickOutside from 'v-click-outside'
    import BigNumber from 'bignumber.js';
    export default {
        components: {
        },
        data () {
            return {
                index:null
            }
        },
        mounted () {
        },
        computed: {
            isMobile() {
                return this.$q.screen.width < 996;
            },
            filterSectionHeight() {
                if (this.$q.screen.width < 996) {
                    return 280;
                }

                return 390;
            },
            getASTRPrice() {
                return BigNumber(this.$store.state.price.ASTR).decimalPlaces(3, 1).toString();
            },
            getSTARPrice() {
                if (this.$store.state.price.STAR > 0)
                    return BigNumber(this.$store.state.price.STAR).decimalPlaces(3, 1).toString();
                else
                    return '0.00'
            }
        },
        watch: {
            
        },
        methods: {
            onClickOutside (event) {
                if (!this.isMobile)
                    return;

                if (document.getElementById("leftSide").style.display == 'block')
                    document.getElementById("leftSide").style.display = 'none';
            },
            opennoegg(index) {
                this.index = index;

                if (!this.isMobile)
                    return;
                document.getElementById("leftSide").style.display = 'none';
            },
            addSTARToken() {
                this.$store.dispatch("addSTARToken");
            },
        }
    }
</script>

<style>
    .cls_close {
        fill:white;
    }

    .cls_close2 {
        fill:white;
    }

    .cls_swap {
        fill:#fff;
        opacity:0;
    }

    .cls_swap2 {
        fill:#231f20;
    }

    nav div.btn {
        min-width: 90%;
        padding:5px 12px 3px 12px;
        font-size:0.8rem;
        border-radius: 5px;
        background: #122d3e;
        /* background: linear-gradient(135deg, #1b3f55 0%,#15223d 100%); */
    }

    nav div.btnBlue {
        background: #002b45;
        color:rgba(255, 255, 255, 0.7)
        /* background: linear-gradient(135deg, #1b3f55 0%,#15223d 100%); */
    }
</style>
